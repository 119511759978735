import * as React from "react"

// styles

// markup
const IonIosStar = () => {
  return (
    <svg viewBox="0 0 35 35">
        <path d="m 6.5291762,33.629702 c -0.0567,-0.0918 -0.0392,-0.2485 0.039,-0.34812 0.0782,-0.0996 0.69452,-1.87053 1.36959,-3.93533 0.67507,-2.06482 1.28846,-3.88936 1.3631,-4.05455 0.0746,-0.16518 0.31187,-0.84094 0.5272,-1.50168 0.2153098,-0.66074 0.4558498,-1.33649 0.5345398,-1.50168 0.3491,-0.73286 0.25085,-0.99146 -0.6070598,-1.59786 -2.225,-1.57272 -6.13213,-4.30884 -7.84084,-5.49085 C 0.86465624,14.473242 0.00306624,13.801682 6.6237716e-5,13.707282 -0.00293376,13.612882 0.10150624,13.494622 0.23214624,13.444482 c 0.13065,-0.0502 3.09479996,-0.0911 6.58699996,-0.0911 h 6.3494598 l 0.2335,-0.63822 c 0.12842,-0.35102 1.0603,-3.1262001 2.07085,-6.1670901 1.75437,-5.27913 1.84915,-5.51654 2.09846,-5.25588 0.14361,0.15014 0.78195,1.86102 1.41853,3.80194 0.63658,1.94092 1.51403,4.57637 1.94988,5.8565601 l 0.79247,2.3276 6.58821,0.079 c 3.62351,0.0435 6.6051,0.0941 6.62575,0.11263 0.15107,0.13515 -0.0297,0.45528 -0.37542,0.66504 -0.48137,0.29201 -5.48241,3.78472 -8.25826,5.76753 -1.37364,0.9812 -1.9089,1.44856 -1.89182,1.65185 0.0131,0.15592 0.88018,2.7838 1.92683,5.83972 2.01767,5.89094 2.16349,6.38214 1.89455,6.38214 -0.0926,0 -2.53947,-1.69728 -5.43753,-3.77173 -4.72179,-3.3799 -5.30278,-3.75427 -5.59263,-3.60363 -0.1779,0.0925 -1.14257,0.75921 -2.14373,1.48167 -5.3111998,3.8327 -8.0195098,5.7544 -8.2162298,5.82989 -0.12079,0.0463 -0.26606,0.009 -0.32282,-0.0827 z" />
    </svg>
  )
}

export default IonIosStar

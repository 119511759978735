import * as React from "react"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import IonAndroidCalendar from "../icons/IonAndroidCalendar"
import IonAndroidFunnel from "../icons/IonAndroidFunnel"
import IonIosStar from "../icons/IonIosStar"
import IonPersonStalker from "../icons/IonPersonStalker"
import ViewportBlock from "../viewportBlock"
import FunfactsItem from "./FunfactsItem"

// styles
import * as styles from "../../css/funfacts.module.css"


const FunfactsSection = ({backgroundImage}) => {
  const funFactItemStyles = {
    funfactItem : styles.funfactItem,
    funfactIcon : styles.funfactIcon
  }

  const image = getImage(backgroundImage)
  const bgImage = convertToBgImage(image)

  const foundation = new Date("2004").getFullYear()
  const currentdate = new Date().getFullYear()
  const age = currentdate - foundation
  return (
    <BackgroundImage className={styles.funfactsSection} {...bgImage}>
      <div className="container">
        <ViewportBlock className={styles.funfactsSectionHead + ' reveal'}>
          <h1 className={styles.funfactsSectionTitle}>Мы в цифрах</h1>  
        </ViewportBlock>
        <div className={styles.funfactsRow}>
          <FunfactsItem icon={<IonAndroidFunnel/>} number="3611" styles={funFactItemStyles}>Скважино-операций в год</FunfactsItem>
          <FunfactsItem icon={<IonPersonStalker/>} number="58" styles={funFactItemStyles}>Инженеров с высшим образованием</FunfactsItem>
          <FunfactsItem icon={<IonIosStar/>} number={age} styles={funFactItemStyles}>Лет в бизнесе</FunfactsItem>
          <FunfactsItem icon={<IonAndroidCalendar/>} number={foundation} styles={funFactItemStyles}>Год основания</FunfactsItem>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default FunfactsSection

// extracted by mini-css-extract-plugin
export var advantageItem = "advantages-module--advantageItem--Swzwt";
export var advantagesMinititle = "advantages-module--advantagesMinititle--xGwHa";
export var advantagesRow = "advantages-module--advantagesRow--53VRO";
export var advantagesSection = "advantages-module--advantagesSection--5lk5R";
export var advantagesSectionHead = "advantages-module--advantagesSectionHead--Fy2HF";
export var advantagesSectionSubtitle = "advantages-module--advantagesSectionSubtitle--NqmgP";
export var advantagesSectionTitle = "advantages-module--advantagesSectionTitle--Qq-YC";
export var icon = "advantages-module--icon--LqpO9";
export var iconContainer = "advantages-module--iconContainer--gFwiw";
export var iconSection = "advantages-module--iconSection--jVkRk";
export var lineBottom = "advantages-module--lineBottom--G+VHt";
export var lineTop = "advantages-module--lineTop--59WHB";
export var sectionDescription = "advantages-module--sectionDescription--cuja9";
import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import IonAndroidArrowDropright from "../icons/IonAndroidArrowDropright"
import IonIosArrowDown from "../icons/IonIosArrowDown"
import TextRotator from "./TextRotator"
import ViewportBlock from "../viewportBlock"

// styles
import * as styles from "../../css/welcome.module.css"
import "../../css/textrotator.css"

const WelcomeSection = () => {

  const customQuery = useStaticQuery(
    graphql`
      query{
        directus {
          textrotatorlines {
            line
            id
          }
          background_video {
            placeholder {
              imageFile {
                publicURL
              }
              id
            }
            id
            video {
              imageFile {
                publicURL
                id
              }
              id
            }
          }
        }
      }
    `
  )

  const nodes = customQuery.directus.textrotatorlines
  const totalCount = nodes.length

  const videosrc = customQuery.directus.background_video.video.imageFile.publicURL
  const poster = customQuery.directus.background_video.placeholder.imageFile.publicURL

  return (
    <section className={styles.welcomeSection}>
      <div className={styles.videoBackground}>
        <ViewportBlock component="video" className={styles.videoTag} autoPlay loop muted playsInline poster={poster} datasrc={videosrc} type="video/webm" />
      </div>
      <div className={styles.videoControlsBox}>
        <div className={styles.welcomeContainer}>
            <a href="https://www.youtube.com/watch?v=PMiGjqNWMmI&feature=emb_imp_woyt&ab_channel=BGSKazakhstan">
              <div className={styles.ionAndroidArrowDropright}>
                <IonAndroidArrowDropright />
              </div>
            </a>
        </div>
      </div>
      <div className={styles.welcomeContainer}>
        <div className={styles.row}>
          <div className={styles.cell}>
            <ViewportBlock className={styles.sectionIntro + ' reveal'}>
              <h1 className={styles.sectionTitle}>Геофизические исследования скважин</h1>
              <TextRotator lines={nodes} length={totalCount}></TextRotator>
              <div className={styles.mountainBtn}>
                <a href="/#advantages">Подробнее</a>
              </div>
            </ViewportBlock>
          </div>
        </div>
        <a href="#advantages" className={styles.ionIosArrowDown}>
          <div>
            <IonIosArrowDown />
          </div>
        </a>
      </div>
    </section>
  )
}

export default WelcomeSection

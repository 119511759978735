import * as React from "react"

// styles

// markup
const IonAndroidCheckmarkCircle = () => {
  return (
    <svg version="1.1" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
        <path d="m17.211 0.0013925c-1.4173 0.01626-2.8632 0.17647-4.0637 0.47765-1.2051 0.30235-1.4596 0.38461-2.374 0.76791-2.0981 0.87952-3.9291 2.147-5.7739 3.9965-1.4091 1.4127-2.6092 3.1041-3.3973 4.788-0.81189 1.7349-1.0063 2.3676-1.4767 4.8143-0.19288 1.0032-0.15677 4.4102 0.0593 5.6084 0.36824 2.0422 0.7586 3.2426 1.5869 4.8773 1.7238 3.4023 4.6961 6.3259 8.119 7.9867 1.5239 0.73941 2.8816 1.1565 5.0529 1.5516v-5.36e-4c0.92149 0.16766 3.9545 0.1761 4.8682 0.01365 2.5033-0.44508 3.3701-0.69808 5.0361-1.4713 2.8315-1.3141 5.525-3.6874 7.4767-6.5884 0.9508-1.4133 1.891-3.7082 2.2937-5.599 0.21187-0.99465 0.45076-3.358 0.36261-3.5876-0.0682-0.17739-0.32117-0.20147-1.8461-0.17059l-1.7669 0.03622-0.0897 1.1548c-0.27457 3.536-1.5847 6.2987-4.1786 8.8118-1.4779 1.4318-1.9998 1.8042-3.6229 2.5856-1.2091 0.58208-2.4252 0.96468-3.9215 1.2335-0.98292 0.17702-3.3473 0.18168-4.1377 0.0084-0.33119-0.0726-0.89081-0.19368-1.2437-0.26874-2.3584-0.50171-4.5492-1.7475-6.4939-3.6926-2.7331-2.7338-4.096-6.0107-4.09-9.8327 5e-3 -3.1101 0.9879-6.1045 2.7586-8.4082 0.70818-0.92134 2.5286-2.6456 3.4146-3.2338 0.73597-0.48863 2.544-1.4079 3.0719-1.5621 2.0643-0.60285 3.1827-0.75545 4.9957-0.68131 1.4324 0.05858 2.1622 0.15131 3.2078 0.40784 0.27815 0.06823 0.49739-0.0988 1.6493-1.2587l1.3282-1.3379c-0.56418-0.39983-1.2624-0.61935-1.887-0.77998-0.28229-0.07165-0.74414-0.18884-1.0264-0.26034-1.0858-0.27493-2.4743-0.40259-3.8916-0.38632zm13.52 4.6022c-0.0706 0-3.4501 3.321-7.5103 7.3799-4.0602 4.059-7.4398 7.3799-7.5103 7.3799-0.0704 0-1.2819-1.1549-2.692-2.5667-1.4101-1.4118-2.6235-2.5672-2.6962-2.5672-0.18326 0-2.3724 2.2164-2.3724 2.4019 0 0.08278 1.7325 1.8933 3.8496 4.0238 3.456 3.4777 3.8716 3.8555 4.0679 3.6973 0.42824-0.34509 17.169-17.198 17.216-17.331 0.0532-0.15179-2.1511-2.4176-2.352-2.4176z" />
    </svg>
  )
}

export default IonAndroidCheckmarkCircle

import * as React from "react"

// styles

// markup
const IonThumbsup = () => {
  return (
    <svg viewBox="0 0 35 35">
        <path d="m 0.0400446,34.792313 c -0.0369,-0.09667 -0.0509,-4.999605 -0.0311,-10.895426 l 0.036,-10.719668 h 5.0814 5.0814104 l 0.0425,0.661274 0.0425,0.661288 0.65365,-0.0014 c 0.77382,-0.0018 1.82287,-0.298659 2.4712,-0.699344 0.5571,-0.344309 1.77297,-1.93488 2.46176,-3.220408 1.11065,-2.0728323 1.46821,-3.6177843 1.63285,-7.0552143 0.14414,-3.00915503 0.22825,-3.28770003 1.03854,-3.43971903 1.28238,-0.240577 3.1651,1.27552903 3.89889,3.13966103 0.50472,1.28219 0.76072,3.916457 0.51006,5.248566 -0.38895,2.0670043 -0.47103,2.9937073 -0.27611,3.1171433 0.10615,0.06723 2.38566,0.124574 5.06557,0.127416 5.50116,0.0058 5.53462,0.01137 6.49337,1.07483 1.37133,1.521061 0.79445,4.279873 -1.03579,4.953391 -0.10297,0.03789 0.0271,0.2691 0.33826,0.60105 0.73957,0.789098 0.87198,1.125725 0.87558,2.225901 0.004,1.12405 -0.28264,1.699724 -1.13632,2.284682 -0.35392,0.242502 -0.45802,0.400263 -0.40509,0.61392 0.0392,0.15814 0.13435,0.534441 0.21149,0.836235 0.28058,1.097726 -0.21427,2.498239 -1.06993,3.028073 -0.44172,0.273507 -0.45686,0.310917 -0.34837,0.860488 0.17041,0.863112 0.0947,1.830321 -0.17566,2.242868 -0.77476,1.182425 -4.93463,2.104853 -11.08859,2.45881 -3.09334,0.177921 -3.68249,0.142549 -7.20706,-0.432616 -1.74294,-0.284421 -2.58494,-0.383296 -2.74465,-0.322288 -0.11255,0.04303 -0.18241,0.514344 -0.20883,1.408949 l -0.0397,1.344341 -5.0503104,0.03652 c -3.96561,0.0287 -5.0647,-0.0017 -5.11738,-0.139222 z m 5.44064,-3.092972 c 0.26965,-0.269641 0.34079,-0.482509 0.33729,-1.009312 -0.007,-0.9939 -0.46608,-1.433308 -1.49881,-1.433308 -0.81446,0 -1.28108,0.51631 -1.28108,1.417475 0,0.961463 0.4129,1.366855 1.39216,1.366855 0.56687,0 0.77714,-0.0684 1.05044,-0.34171 z" />
    </svg>
  )
}

export default IonThumbsup

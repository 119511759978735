import * as React from "react"
import CountUp from "react-countup"
import ViewportBlock from "../viewportBlock"

const FunfactsItem = ({ icon, number, children, styles }) => {
  return (
    <ViewportBlock className={styles.funfactItem + ' reveal'}>
      <div className={styles.funfactIcon}>{icon}</div>
      <h4><CountUp end={number} /></h4>
      <p>{children}</p>
    </ViewportBlock>
  )
}

export default FunfactsItem

import * as React from "react"

// styles

// markup
const IonIosArrowDown = () => {
  return (
    <svg viewBox="0 0 50 29.6">
      <path d="M 0,3.0138302 2.94339,-9.9809265e-5 25.0262,23.1785 47.08409,-9.9809265e-5 50,3.0138302 25.0262,29.6001 Z" />
    </svg>
  )
}

export default IonIosArrowDown

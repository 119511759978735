import * as React from "react"

// styles

// markup
const IonAndroidArrowDropright = () => {
  return (
    <svg viewBox="0 0 17.466999 35">
      <path d="M 1.6102698e-4,1e-7 17.466838,17.529049 1.6102698e-4,35 Z" />
    </svg>
  )
}

export default IonAndroidArrowDropright

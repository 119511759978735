import * as React from "react"

// styles

// markup
const IonAndroidFunnel = () => {
  return (
    <svg viewBox="0 0 35 35">
        <path d="m 0.0530578,6.2935119 -0.04209,1.693829 c -0.02317,0.93162 -0.0084,1.78141 0.0327,1.88852 0.0613,0.1594901 3.211376,0.1880301 17.5154132,0.1571501 l 17.44092,-0.0375 v -1.85098 -1.850979 h -17.47362 z m 6.180323,8.9569391 -0.0896,0.33429 c -0.14947,0.55778 -0.10396,3.2216 0.0603,3.52845 0.15042,0.28105 0.39957,0.28629 11.3650602,0.2492 l 11.21155,-0.0378 v -1.99904 -1.99904 l -11.27362,-0.0379 z m 9.5168102,9.50319 c -1.45161,0.003 -1.95507,0.0495 -2.02418,0.15836 -0.13907,0.21908 -0.16655,3.23506 -0.0327,3.58387 0.0805,0.20975 0.51564,0.23215 3.81308,0.19711 l 3.72225,-0.0394 v -1.92516 -1.92486 l -3.69166,-0.0397 c -0.71366,-0.008 -1.30292,-0.0114 -1.78679,-0.0103 z" />
    </svg>
  )
}

export default IonAndroidFunnel

import * as React from "react"
import Menuzord from "../components/mainpage/Menuzord"
import ContactusSection from "../components/mainpage/ContactusSection"
import PartnersSection from "../components/mainpage/PartnersSection"
import NewsSection from "../components/mainpage/NewsSection"
import FunfactsSection from "../components/mainpage/FunfactsSection"
import AdvantagesSection from "../components/mainpage/AdvantagesSection"
import WelcomeSection from "../components/mainpage/WelcomeSection"
import FooterSection from "../components/mainpage/FooterSection"
import { graphql } from "gatsby"

import Seo from "../components/seo"
const IndexPage = ( {data} ) => {
  const allDirectusArticle = data.directus.articles
  const { files } = data.directus_system;
  return (
    <> 
      <Seo title="Главная"/>
      <Menuzord pageName="main" />
      <WelcomeSection />
      <AdvantagesSection />
      <FunfactsSection backgroundImage={files[0].imageFile} />
      <NewsSection data={allDirectusArticle} />
      <PartnersSection />
      <ContactusSection />
      <FooterSection />
    </>
  )
}

export default IndexPage


export const query = graphql`
  query NewsSectionItem {
    directus {
      articles {
        id
        title
        date
        preview_content
        url
        image {
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          id
        }
      }
    }
    directus_system {
      files(filter: {title: {_eq: "Thumb  1200 0 0 0 Auto"}}) {
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        id
      }
    }
  }
`
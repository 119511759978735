import * as React from "react"

class TextRotator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {textClass: 1 , line: 0};
  }

  componentDidMount() {
    this.timerID = setTimeout(() => 
      this.tick(),
      1000
    );
  }

  tick() {
    let textClass = this.state.textClass;
    let line = this.state.line;
    textClass++;
    if(textClass >= 2) {
      textClass = 0;
      this.timerID=setTimeout(() =>
        this.tick(), 1000);
    } else {
      this.timerID=setTimeout(() =>
        this.tick(), 2500);
        line++;
    }
    this.setState({
      textClass: textClass
    });
    if(line >= this.props.length) {
      line = 0;
    }
    this.setState({
      line: line
    });
  }

  render() {
    const textLength = this.state.textClass ? 'textLong' : 'textShort';
  
    return (
      <h2 className="cdHeadline">
        <span className={`cdWordsWrapper ${textLength}`}>
          {this.props.lines.map(({ id, line }, index) => {
            return (
              <b key={id} className={ this.state.line === index ? 'isVisible' : 'isHidden' }>{line}&nbsp;</b>
            )
          })}
        </span>
      </h2>
    );
  }
}

export default TextRotator
import * as React from "react"
// styles

// markup
const AdvantagesItem = ({ icon, title, children, styles}) => {
  return (
    <>
      <div className={styles.iconContainer}>
        <div className={styles.iconSection}>
          <div className={styles.lineTop}></div>
          <div className={styles.icon}>
            {icon}
          </div>
          <div className={styles.lineBottom}></div>
        </div>
      </div>
      <div className={styles.sectionDescription}>
        <h4 className={styles.advantagesMinititle}>{title}</h4>
        <p>{children}</p>
      </div>
    </>
  )
}

export default AdvantagesItem

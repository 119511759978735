import * as React from "react"
import * as styles from "../../css/advantages.module.css"
import IonAndroidCheckmarkCircle from "../icons/IonAndroidCheckmarkCircle"
import IonAndroidContacts from "../icons/IonAndroidContacts"
import IonAndroidShareAlt from "../icons/IonAndroidShareAlt"
import IonIosGear from "../icons/IonIosGear"
import IonRibbonB from "../icons/IonRibbonB"
import IonThumbsup from "../icons/IonThumbsup"
import AdvantagesItem from "./AdvantagesItem"
import ViewportBlock from "../viewportBlock"

// styles

// markup
const AdvantagesSection = () => {

  const advandtagesItemStyle = {
    iconContainer : styles.iconContainer,
    iconSection : styles.iconSection,
    lineTop : styles.lineTop,
    lineBottom : styles.lineBottom,
    icon : styles.icon,
    sectionDescription : styles.sectionDescription,
    advantagesMinititle : styles.advantagesMinititle
  }
  return (
    <section id="advantages" className={styles.advantagesSection}>
      <div className="container">
        <ViewportBlock className={styles.advantagesSectionHead + ' reveal'}>
          <div>
            <h1 className={styles.advantagesSectionTitle}>Наши преимущества</h1>
            <p className={styles.advantagesSectionSubtitle}>Всегда открыты для сотрудничества</p>
          </div>  
        </ViewportBlock>
        <div className={styles.advantagesRow}>
          <ViewportBlock className={styles.advantageItem + ' reveal'}>
            <AdvantagesItem icon={<IonAndroidCheckmarkCircle/>} title="Компетентность" styles={advandtagesItemStyle}><strong>ТОО “БатысГеофизСервис”</strong> имеет гос. лицензию на проведение всех видов геофизических работ, лицензию на обращение с источниками ионизирующего излучения, а также все разрешительные документы на право производтсва работ и перевозку всех взрывчатых и радиоционных веществ.</AdvantagesItem>
          </ViewportBlock>
          <ViewportBlock className={styles.advantageItem + ' reveal'}>
            <AdvantagesItem icon={<IonAndroidContacts/>} title="Международное сотрудничество" styles={advandtagesItemStyle}>C <strong>2005</strong> года мы является членом <strong>междунарожной ассоциации научно-технического и делового сотрудничества по геофизическим исследованиям и работам в скважинах</strong> и членом <strong>Евроазиатского геофизического общества</strong>(ЕАГО).</AdvantagesItem>
          </ViewportBlock>
          <ViewportBlock className={styles.advantageItem + ' reveal'}>
            <AdvantagesItem icon={<IonAndroidShareAlt/>} title="Оснащение" styles={advandtagesItemStyle}>Производственная база включает в себя геофизическую лабораторию, бокс для ремонта и содержания транспортных средств, базисный склад взрывчатых материалов, хранилище источников ионизирующего излучения, диспетчерскую службу, производственно-технический отдел, ремонтно-механическую мастерскую.</AdvantagesItem>
          </ViewportBlock>
          <ViewportBlock className={styles.advantageItem + ' reveal'}>
            <AdvantagesItem icon={<IonIosGear/>} title="Социальная ответственность" styles={advandtagesItemStyle}><strong>Главной ценностью</strong> компании являются <strong>сотрудники,</strong> создание <strong>хороших условий труда, развитие персонала,</strong> приоритет социальной и кадровой политики.</AdvantagesItem>
          </ViewportBlock>
          <ViewportBlock className={styles.advantageItem + ' reveal'}>
            <AdvantagesItem icon={<IonRibbonB/>} title="Профессионализм" styles={advandtagesItemStyle}>Компания и сотрудники сертифицированны по программе системы менеджмента качества в соответствии с требованиями <strong>ISO-9001-2001.</strong></AdvantagesItem>
          </ViewportBlock>
          <ViewportBlock className={styles.advantageItem + ' reveal'}>
            <AdvantagesItem icon={<IonThumbsup/>} title="Нам доверяют" styles={advandtagesItemStyle}>В число наших заказчиков входит <strong>АО УзеньМунайГаз, АО КаражанбасМунай, ТОО MunaiFieldService, ТОО СП КазГерМунай.</strong></AdvantagesItem>
          </ViewportBlock> 
        </div>
      </div>
    </section>
  )
}

export default AdvantagesSection

// extracted by mini-css-extract-plugin
export var cell = "welcome-module--cell--X6Qo3";
export var fadeInDown = "welcome-module--fadeInDown--RZ9vT";
export var ionAndroidArrowDropright = "welcome-module--ionAndroidArrowDropright--nWKco";
export var ionIosArrowDown = "welcome-module--ionIosArrowDown--8pESi";
export var mountainBtn = "welcome-module--mountainBtn--VRGB-";
export var row = "welcome-module--row--FOi+c";
export var sectionIntro = "welcome-module--sectionIntro--wpbv0";
export var sectionTitle = "welcome-module--sectionTitle--PhJHA";
export var videoBackground = "welcome-module--videoBackground--zhuK8";
export var videoControlsBox = "welcome-module--videoControlsBox--flKyJ";
export var videoTag = "welcome-module--videoTag--IedOW";
export var welcomeContainer = "welcome-module--welcomeContainer--9OPBQ";
export var welcomeSection = "welcome-module--welcomeSection--JTyzk";
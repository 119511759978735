import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { graphql, useStaticQuery } from "gatsby"
import ViewportBlock from "../viewportBlock"

// styles
import "swiper/css"
import "swiper/css/pagination"
import * as styles from "../../css/partners.module.css"

const PartnersSection = () => {
  const sliderItems = useStaticQuery(
    graphql`
      query{
        directus {
          slider_images {
            image {
              imageFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              id
            }
            alt
          }
        }
      }
    `
  )
  const nodes = sliderItems.directus.slider_images
  return (
    <section className={styles.sectionPartners}>
      <div className="container">
        <ViewportBlock className={styles.sectionHead + ' reveal'}>
          <h1 className={styles.sectionTitle}>Наши уважаемые партнёры</h1>  
        </ViewportBlock>
        <div className={styles.row}>
          <ViewportBlock className={styles.slideContainer + ' reveal'}>
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              breakpoints={{
                800: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              loop={true}
              autoplay={{
                delay: 2500,
              }}
              modules={[Autoplay]}
            >
              {nodes.map(({ id, image, alt }) => {
                return (
                  <SwiperSlide key={id}><GatsbyImage image={getImage(image.imageFile)} alt={alt} /></SwiperSlide>
                )
              })}
            </Swiper>
          </ViewportBlock>
        </div>
      </div>
    </section>
  )
}

export default PartnersSection
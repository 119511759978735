import * as React from "react"
import { Link } from "gatsby"
import Article from "./Article"
import ViewportBlock from "../viewportBlock"

// styles
import * as styles from "../../css/news.module.css"

const NewsSection = ({ data }) => {
  const articlestyles = {
    articleItem : styles.articleItem,
    image : styles.image,
    datePublished : styles.datePublished,
    articleMinititle : styles.articleMinititle,
    articleSectionDescription : styles.articleSectionDescription
  }
  const nodes = data;
  const articles = nodes.map(article => {
    return (
      <Article key={article.id} data={article} styles={articlestyles}></Article>
    )
  });
  return (
    <section className={styles.newsSection}>
      <div className="container">
        <ViewportBlock className={styles.newsSectionHead + ' reveal'}>
          <h1 className={styles.newsSectionTitle}>Новости</h1>  
        </ViewportBlock>
        <div className={styles.newsRow}>
          {articles}
        </div>
        <ViewportBlock className={styles.newsSectionFoot + ' reveal'}>
          <Link to="/news" className={styles.seeAllBtn}>Посмотреть все новости</Link>
        </ViewportBlock>
      </div>
    </section>
  )
}

export default NewsSection


import * as React from "react"

// styles

// markup
const IonAndroidCalendar = () => {
  return (
    <svg viewBox="0 0 35 35">
        <path d="m 8.043,0.00249955 c -2.29226,0 -2.3814,0.06456 -2.3814,1.72781105 v 1.188593 H 4.15095 c -1.36977,0 -1.58302,0.04606 -2.28983,0.494312 C 1.0741,3.9123306 0.58335,4.4930616 0.20593,5.3718686 0.0515,5.7314686 0,9.1412366 0,18.975437 c 0,12.65241 0.0119,13.14753 0.33293,13.77688 0.38035,0.74555 1.36594,1.66904 2.14143,2.00671 0.42698,0.18594 3.25575,0.23103 14.78059,0.23646 15.88623,0.007 15.23971,0.0538 16.43226,-1.16755 0.34754,-0.3559 0.78496,-1.06625 0.97214,-1.57836 C 34.98699,31.353217 35,30.857237 35,18.945967 35,6.4378566 34.9834,6.0751786 34.36255,4.8919396 33.59436,3.4278776 32.63181,2.9189036 30.63153,2.9189036 h -1.46504 v -1.188593 c 0,-1.66559805 -0.0868,-1.72781105 -2.40209,-1.72781105 -2.31576,0 -2.40175,0.06211 -2.40175,1.72991605 v 1.190697 l -6.90563,-0.04491 -6.90563,-0.0449 -0.0488,-1.200871 C 10.46709,0.75639655 10.37713,0.37328755 10.16966,0.21685455 9.9832,0.07624655 9.25066,0.00250055 8.04296,0.00250055 Z M 4.03167,12.181727 l 13.38255,0.0439 13.3822,0.0438 0.0442,9.30772 0.0446,9.30738 H 17.45847 4.03171 v -9.35123 z m 14.75498,6.60742 -0.046,4.27725 c -0.0355,3.30743 0.003,4.30836 0.17155,4.41513 0.28392,0.18022 8.21481,0.18022 8.49872,0 0.16818,-0.10677 0.20706,-1.1077 0.17156,-4.41513 l -0.046,-4.27725 H 23.1617 Z" />
    </svg>
  )
}

export default IonAndroidCalendar
